<template>
  <Page is-header>
    <!-- 设备信息 -->
    <div class="device-info u-p-12 bg-white">
      <div class="u-flex u-m-b-4">
        <span>设备编号：{{ refundOrderDetail.deviceCode || "--" }}</span>
        <span class="device-type u-m-l-8">
          {{ ["", "娃娃机", "兑币机", "游艺机"][refundOrderDetail.deviceType] }}
        </span>
      </div>
      <p class="u-tips-color u-font-13 u-line-height-17">
        设备名称: {{ refundOrderDetail.deviceName || "--" }}
      </p>
    </div>
    <!-- 商品信息 -->
    <div class="bg-white u-p-12 u-m-t-12 u-flex u-col-top">
      <div class="order-image u-m-r-10">
        <van-image width="72" height="72" radius="4" :src="coinImg" />
      </div>
      <div class="goods-info u-flex-1">
        <p class="u-line-height-18 u-flex u-row-between">
          <span>{{ refundOrderDetail.goodsPackageName }}</span>
          <van-icon
            class-prefix="iconfont"
            name="question"
            size="18"
            color="#666666"
            @click="isShowTipsPop = true"
          />
        </p>
        <p class="u-m-t-8 u-flex u-flex-wrap">
          <!-- 币类型 -->
          <Tag
            class="u-m-r-10 u-m-b-6"
            :text="['', '购币', '储币'][refundOrderDetail.tradeType]"
            bg="#f0f2f5"
            color="#999999"
          />
          <!-- 支付状态 -->
          <Tag
            class="u-m-r-10 u-m-b-6"
            :text="payStatusFmt(refundOrderDetail.orderState)"
            :bg="
              refundOrderDetail.orderState === 'PAY_SUCCESS'
                ? '#f0f2f5'
                : 'rgba(245, 108, 108, 0.1)'
            "
            :color="
              refundOrderDetail.orderState === 'PAY_SUCCESS'
                ? '#999999'
                : '#f56c6c'
            "
          />
          <!-- 出货状态 -->
          <Tag
            v-if="refundOrderDetail.tradeType != 2"
            class="u-m-r-10 u-m-b-6"
            :text="shipmentFmt(refundOrderDetail.shipmenState)"
            :bg="
              refundOrderDetail.shipmenState === 'FALL'
                ? '#f0f2f5'
                : 'rgba(245, 108, 108, 0.1)'
            "
            :color="
              refundOrderDetail.shipmenState === 'FALL' ? '#999999' : '#f56c6c'
            "
          />
          <!-- 已出币 -->
          <Tag
            v-if="refundOrderDetail.tradeType != 2"
            class="u-m-r-10 u-m-b-6"
            :text="`已出${
              refundOrderDetail.fallCoinNum == null
                ? '未知个'
                : refundOrderDetail.fallCoinNum
            }币`"
            bg="#f0f2f5"
            color="#999999"
          />
          <!-- 故障码（出货失败才显示） -->
          <Tag
            v-if="
              refundOrderDetail.shipmenState === 'NOT_FALL' &&
              refundOrderDetail.shipmenCode != '0'
            "
            class="u-m-r-10 u-m-b-6"
            :text="`${refundOrderDetail.shipmenCode || ''}故障`"
            bg="rgba(245, 108, 108, 0.1)"
            color="#f56c6c"
          />
        </p>
        <div class="u-flex u-row-between u-m-t-16">
          <span class="u-line-height-20">
            ￥{{ amountFmt(refundOrderDetail.mchAmount) }}
          </span>
          <span class="u-line-height-20 u-color-red">
            退款金额：￥{{ amountFmt(refundOrderDetail.refundAmount) }}
          </span>
        </div>
      </div>
    </div>
    <!-- 退款信息 -->
    <div class="bg-white u-p-12 u-m-t-12">
      <p class="u-line-height-20 u-m-b-10">退款信息</p>
      <div
        v-for="info in OrderKeys"
        :key="info.key"
        class="u-flex u-col-top info-item u-row-between"
      >
        <span class="u-font-14 u-line-height-22 u-tips-color item-label">
          {{ info.label }}
        </span>
        <div class="u-font-14 u-line-height-22 u-flex-1">
          {{
            info.format
              ? info.format(refundOrderDetail[info.key])
              : refundOrderDetail[info.key] || "--"
          }}
        </div>
        <span
          v-if="info.key === 'tradeNo'"
          class="u-m-l-10 copy u-line-height-22"
          @click="handleCopy"
        >
          <van-icon
            class-prefix="iconfont"
            name="fuzhi"
            color="#999999"
            size="18"
          />
        </span>
      </div>
    </div>
    <!-- 提示 -->
    <van-popup
      v-model:show="isShowTipsPop"
      round
      :lock-scroll="true"
      :close-on-popstate="true"
      teleport="body"
      position="center"
      close-on-click-overlay
      :style="{ width: '87%' }"
    >
      <div class="u-p-24">
        <p class="u-font-20 u-p-b-12 u-text-center">说明</p>

        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          1、只有天鹿主板支持显示出币数
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          2、111故障：消息超时未出币自动退款
        </p>
        <p class="u-font-16 u-p-b-12 u-text-left u-line-height-24">
          3、112故障：出币失败
        </p>
      </div>
    </van-popup>
  </Page>
</template>

<script>
import { defineComponent, onActivated, ref } from "vue";
import { useRoute } from "vue-router";
import { OrderKeys } from "./const";
import { useCopy } from "@/hooks/useCopy";
import { useActions, useGetters } from "@/hooks/useVuex";
import { payStatusFmt, amountFmt, shipmentFmt } from "@/utils/format";
import Tag from "@/components/Tag.vue";
export default defineComponent({
  name: "RefundOrderDetail",
  components: { Tag },
  setup() {
    const isShowTipsPop = ref(false);
    const route = useRoute();
    const id = ref("");
    const { getMchRefundOrderDetailAction } = useActions("order", [
      "getMchRefundOrderDetailAction",
    ]);
    const { refundOrderDetail } = useGetters("order", ["refundOrderDetail"]);
    onActivated(() => {
      id.value = route.query.id;
      getMchRefundOrderDetailAction({
        id: id.value,
      });
    });
    const handleCopy = () => {
      useCopy(refundOrderDetail.value.tradeNo);
    };
    return {
      isShowTipsPop,
      refundOrderDetail,
      coinImg: require("@/assets/images/ic_coin.png"),
      OrderKeys,
      amountFmt,
      shipmentFmt,
      handleCopy,
      payStatusFmt,
    };
  },
});
</script>

<style lang="scss" scoped>
.info-item {
  padding: 12px 0;
  .item-label {
    display: inline-block;
    width: 100px;
  }
  .copy {
    display: inline-block;
    width: 26px;
    text-align: right;
  }
}
</style>
