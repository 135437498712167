<template>
  <van-popup
    :show="show"
    round
    teleport="body"
    position="bottom"
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      :title="title"
      :min-date="minDate"
      :max-date="maxDate"
      @cancel="close"
    >
      <template #confirm>
        <span class="u-theme-color" @click="handleConfirm">确认</span>
      </template>
    </van-datetime-picker>
  </van-popup>
</template>

<script>
import { defineComponent, reactive, toRefs, watchEffect } from "vue";
export default defineComponent({
  name: "TimePickPop",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Date,
      default: new Date(),
    },
    title: {
      type: String,
      default: "请选择日期",
    },
    minDate: {
      type: Date,
      default: new Date(1970, 0, 1),
    },
    maxDate: {
      type: Date,
      default: new Date(2100, 0, 1),
    },
  },
  emits: ["update:show", "update:modelValue"],
  setup(props, { emit }) {
    const state = reactive({
      currentDate: props.modelValue,
    });

    const close = () => {
      emit("update:show", false);
    };
    const handleConfirm = () => {
      // state.currentDate = state.tempDate;
      emit("update:modelValue", state.currentDate);
      close();
    };
    watchEffect(() => {
      state.currentDate = props.modelValue;
    });
    return {
      ...toRefs(state),
      close,
      handleConfirm,
    };
  },
});
</script>
