<template>
  <Page is-header>
    <van-notice-bar
      left-icon="volume-o"
      mode="closeable"
      text="仅支持退款1次，且不支持撤销，请谨慎操作~"
    />
    <div class="reset-box">
      <van-form ref="RefundAddForm" validate-trigger="onSubmit">
        <van-field
          v-model="refundAddForm.mchAmount"
          name="mchAmount"
          label="商品金额"
          placeholder="暂未获取到商品金额"
          readonly
        />
        <van-field
          v-model="refundAddForm.refundAmount"
          name="refundAmount"
          label="退款金额"
          clearable
          placeholder="请输入退款金额"
          type="number"
          :rules="[
            {
              required: true,
              message: '请输入正确的退款金额',
              trigger: 'onBlur',
              validator: validatorRefundAmount,
            },
          ]"
        />
      </van-form>
    </div>
    <!-- 新增保存按钮 (不在底部) -->
    <div class="u-m-t-32 content">
      <van-button
        :loading="loading"
        loading-text="请稍后..."
        round
        type="primary"
        @click="handleSubmitSave"
      >
        提交退款
      </van-button>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, onActivated } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { addRefundApplyApi } from "@/api/order";
export default defineComponent({
  name: "RefundAdd",
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const RefundAddForm = ref(null);
    const loading = ref(false);
    const refundAddForm = reactive({
      orderId: "",
      payConfigId: "",
      mchId: "",
      mchAmount: "",
      refundAmount: "",
    });
    onActivated(() => {
      // 按道理这个页面入参只有orderId, 但是朋举说查都查了就传给他，如果详情不进入退款，还不是照样需要查一遍，更tm浪费
      refundAddForm.orderId = route.query.orderId || "";
      refundAddForm.payConfigId = route.query.payConfigId || "";
      refundAddForm.mchId = route.query.mchId || "";
      refundAddForm.mchAmount = route.query.mchAmount || "";
      refundAddForm.refundAmount = route.query.mchAmount || "";
    });
    // 添加保存
    const handleSubmitSave = async () => {
      const validor = await validateFormat();
      if (!validor) return false;
      loading.value = true;
      let data = {
        orderId: refundAddForm.orderId,
        mchId: refundAddForm.mchId,
        refundAmount: refundAddForm.refundAmount,
        payConfigId: refundAddForm.payConfigId,
      };
      try {
        const { code } = await addRefundApplyApi(data);
        if (code === 200) {
          loading.value = false;
          Toast("提交成功");
          router.back();
        }
      } catch (error) {
        console.log("error", error);
        loading.value = false;
      }
    };
    // 表单校验
    const validateFormat = async () => {
      try {
        await RefundAddForm.value.validate();
        return true;
      } catch (error) {
        console.log("error", error);
        return false;
      }
    };
    const validatorRefundAmount = (value) => {
      if (value === "" || value == 0) return false;
      else if (value > refundAddForm.mchAmount) return false;
      return true;
    };
    return {
      loading,
      refundAddForm,
      RefundAddForm,
      handleSubmitSave,
      validatorRefundAmount,
    };
  },
});
</script>

<style lang="scss" scoped></style>
