<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <!-- 日期选择 -->
      <TimePick @change="handleTimeChange" />
      <!-- 搜索框 -->
      <Search
        v-model="deviceCode"
        placeholder="请输入设备编号或名称搜索"
        @on-search="handleSearch"
      />
    </van-sticky>

    <div class="order-list-box content">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getRecordList"
      >
        <template v-if="refundRecordPage.length">
          <template v-for="record in refundRecordPage" :key="record.tradeNo">
            <RecordItem :orderInfo="record" />
          </template>
        </template>
        <template v-else>
          <van-empty image="search" description="" />
        </template>
      </van-list>
    </div>
  </Page>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onActivated } from "vue";
import TimePick from "@/components/TimePick";
import Search from "@/components/Search";
import RecordItem from "./components/RecordItem.vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { getRefundRecordApi } from "@/api/order";
import moment from "moment";
export default defineComponent({
  name: "RefundRecordList",
  components: {
    TimePick,
    Search,
    RecordItem,
  },
  setup() {
    const route = useRoute();
    const refundRecordPage = ref([]);
    const isRender = ref(true);
    const state = reactive({
      page: 1,
      pageSize: 10,
      totalSize: 0,
      loading: false,
      finished: true,
      currentDate: moment(new Date()).format("YYYY-MM-DD"),
    });
    const deviceCode = ref("");
    onBeforeRouteLeave((to) => {
      if (to.name === "RefundOrderDetail") {
        isRender.value = false;
      } else {
        isRender.value = true;
      }
    });
    // 回显参数
    onActivated(() => {
      if (isRender.value) {
        deviceCode.value = route.query.deviceCode;
        handleSearch();
      }
    });
    const handleSearch = () => {
      console.log(123123);
      state.page = 1;
      getRecordList();
    };
    // 请求列表接口
    const getRecordList = async () => {
      try {
        const {
          code,
          content: { currentList, totalSize },
        } = await getRefundRecordApi({
          refundBeginTime: state.currentDate + " 00:00:00",
          refundEndTime: state.currentDate + " 23:59:59",
          page: state.page,
          pageSize: 10,
          deviceCode: deviceCode.value,
        });
        if (code === 200) {
          // console.log("content", content);
          if (state.page === 1) {
            refundRecordPage.value = currentList;
          } else {
            refundRecordPage.value = refundRecordPage.value.concat(currentList);
          }
          state.finished = Math.ceil(totalSize / 10) <= state.page;
          state.page++;
          state.loading = false;
        }
      } catch (error) {
        console.log("error", error);
        state.loading = false;
      }
    };
    const handleTimeChange = (time) => {
      console.log(time);
      state.currentDate = time;
      handleSearch();
    };
    return {
      ...toRefs(state),
      deviceCode,
      refundRecordPage,
      handleSearch,
      getRecordList,
      handleTimeChange,
    };
  },
});
</script>

<style lang="scss" scoped></style>
