/**
 * 使用复制的hook
 */
import { Toast } from "vant";
export const useCopy = (value) => {
  const I = document.createElement("input");
  I.value = value;
  document.body.appendChild(I);
  I.select();
  document.execCommand("Copy");
  Toast("复制成功");
  I.remove();
};
