export const OrderKeys = [
  {
    label: "退款时间",
    key: "gmtCreate",
    format: null,
  },
  {
    label: "订单时间",
    key: "orderCreate",
    format: null,
  },
  {
    label: "订单号",
    key: "tradeNo",
    format: null,
  },
  {
    label: "处理人",
    key: "createBy",
    format: null,
  },
];

export const CoinType = [
  { label: "储币", value: 1 },
  { label: "购币", value: 2 },
];
