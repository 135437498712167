<template>
  <div class="pick-box u-flex u-row-between">
    <div class="pick-pre u-flex" @click="handleChangeTime(-1)">
      <i class="iconfont iconfont-left u-font-14" />
      <span class="u-m-l-6">前一天</span>
    </div>
    <div
      class="pick-time u-flex bg-white u-row-between"
      @click="handleChangeShowPick"
    >
      <span>{{ pickDate }}</span>
      <i class="iconfont iconfont-left i-down u-font-14" />
    </div>
    <div
      class="pick-next u-flex"
      :class="{ disabled: isMaxDate }"
      @click="handleChangeTime(1)"
    >
      <span class="u-m-r-6">后一天</span>
      <i class="iconfont iconfont-left i-right u-font-14" />
    </div>
  </div>
  <!-- <van-popup v-model:show="isShowPick" position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      title="请选择日期"
      :max-date="maxDate"
      @cancel="handleChangeShowPick"
    >
      <template #confirm>
        <span class="u-theme-color" @click="handleConfirm">确认</span>
      </template>
    </van-datetime-picker>
  </van-popup> -->
  <TimePickPop
    v-model="currentDate"
    v-model:show="isShowPick"
    :max-date="maxDate"
    title="请选择时间"
    @update:modelValue="handleConfirm"
  />
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  computed,
  onDeactivated,
} from "vue";
import moment from "moment";
import TimePickPop from "./TimePickPop.vue";
export default defineComponent({
  name: "TimePick",
  props: {
    defaultTime: {
      type: Date,
      default: new Date(),
    },
  },
  components: { TimePickPop },
  emits: ["change"],
  setup(props, { emit }) {
    const state = reactive({
      isShowPick: false,
      currentDate: props.defaultTime,
      maxDate: props.defaultTime,
      tempDate: props.defaultTime,
    });
    onDeactivated(() => {
      state.isShowPick = false;
    });
    const pickDate = computed(() => {
      return moment(state.currentDate).format("YYYY-MM-DD");
    });
    const isMaxDate = computed(() => {
      return moment(state.currentDate).isSame(state.maxDate, "day");
    });
    const handleChangeShowPick = () => {
      state.isShowPick = !state.isShowPick;
    };

    const handleConfirm = () => {
      // state.currentDate = state.tempDate;
      changeTime();
    };

    const changeTime = () => {
      emit("change", pickDate.value);
    };

    const handleChangeTime = (type) => {
      if (type > 0) {
        if (isMaxDate.value) {
          return false;
        }
        const next = moment(state.currentDate).add(1, "day");
        state.currentDate = new Date(next);
        console.log("后一天", state.currentDate);
        changeTime();
      } else {
        const pre = moment(state.currentDate).subtract(1, "day");
        state.currentDate = new Date(pre);
        console.log("前一天", state.currentDate);
        changeTime();
      }
    };
    return {
      ...toRefs(state),
      pickDate,
      isMaxDate,
      handleChangeShowPick,
      handleConfirm,
      handleChangeTime,
    };
  },
});
</script>

<style lang="scss" scoped>
.pick-box {
  width: 100%;
  background-color: #027aff;
  color: #ffffff;
  padding: 6px 10px;
  .pick-time {
    padding: 6px 12px;
    color: #333333;
    width: 132px;
    border-radius: 2px;
  }
  .disabled {
    color: #cdcdcd;
  }
}
.i-down {
  transform: rotateZ(-90deg);
}
.i-right {
  transform: rotateY(-180deg);
}
</style>
