<template>
  <div v-bind="$attrs" class="tag" :style="{ backgroundColor: bg, color }">
    {{ text }}
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Tag",
  props: {
    bg: {
      type: String,
      default: "#027AFF",
    },
    text: {
      type: [String, Number],
      default: "娃",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
});
</script>

<style lang="scss" scoped>
.tag {
  display: flex;
  padding: 2px 5px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  line-height: normal;
}
</style>
