/**
 * 存储常用定量
 * created by lizhicai
 * 2022-12-19
 */

export const PayTypeIcons = {
  微信: { icon: "weixinzhifu1", color: "#84D945" },
  支付宝: { icon: "zhifubao", color: "#027AFF" },
  现金: { icon: "xianjin", color: "#F2AE42" },
};
