<template>
  <div class="bg-white u-radius-4 u-p-12 u-m-t-12" @click="handleToOrderDetail">
    <div class="u-flex u-row-between u-m-b-12 u-line-height-20">
      <!-- <i class="iconfont iconfont-qidongjilu u-font-20" /> -->
      <div class="u-flex u-flex-1">
        <van-icon
          size="20"
          class-prefix="iconfont"
          :name="
            orderInfo.mainPayType
              ? PayTypeIcons[orderInfo.mainPayType].icon
              : ''
          "
          :color="
            orderInfo.mainPayType
              ? PayTypeIcons[orderInfo.mainPayType].color
              : ''
          "
        />
        <span class="u-font-15 u-m-l-8">{{ orderInfo.tradeNo }}</span>
      </div>
      <van-icon size="18" name="arrow" color="#979797" />
    </div>
    <p class="u-flex u-m-b-12">
      <span class="u-font-15 u-content-color u-m-r-10">
        处理人：{{ orderInfo.createBy || "--" }}
      </span>
    </p>
    <p class="u-font-15 u-flex u-row-between">
      <span class="u-tips-color">{{ orderInfo.gmtCreate }}</span>
      <span class="u-color-red">
        退款金额：￥{{ amountFmt(orderInfo.refundAmount) }}
      </span>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { amountFmt, payStatusFmt } from "@/utils/format";
import { useRouter } from "vue-router";
import { PayTypeIcons } from "@/hooks/useConst";
export default defineComponent({
  name: "RefundRecordItem",
  props: ["orderInfo"],
  components: {},
  setup(props) {
    const router = useRouter();
    const handleToOrderDetail = () => {
      router.push({
        path: "/refund/detail",
        query: {
          id: props.orderInfo.id,
        },
      });
    };
    return {
      PayTypeIcons,
      amountFmt,
      payStatusFmt,
      handleToOrderDetail,
    };
  },
});
</script>
